<template>
    <b-form class="row" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData">

        <div class="col-12">
            <validated-input label="Question*" name="Question" v-model="model.question"
                             :rules="{required: true}" :disabled="loading"/>
        </div>

        <div class="col-6">
            <validated-input label="Order" type="number" v-model="model.order"
                             :rules="{required: false, min_value: 1}" :disabled="loading"/>
        </div>

        <div class="col-6">
            <validated-vue-select label="Question Type" v-model="model.question_type"
                                  :rules="{required: false}" :disabled="loading" :options="questionTypes"/>
        </div>

        <div class="col-12 mb-2">
            <label>Description*</label>
            <ckeditor class="form-control" v-model="model.description"
                      :editor="editor" :config="editorConfig"/>
        </div>
        <div class="col-12">
            <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
        </div>

        <div class="col-12 text-right">
            <btn class="trn" size="sm" :loading="loading" color="primary" text="Update"
                 loading-text="Updating..." :disabled="loading"/>
        </div>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';

export default {
    name       : 'EditFAQ',
    components : { ckeditor : CKEditor.component },
    props      : { initialData : { type : Object } },

    data () {
        return {
            addUrl       : urls.cms.faq.addEdit,
            editorConfig : {
                toolbar : {
                    items : [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'undo',
                        'redo'
                    ]
                },
                table : {
                    contentToolbar : ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                language : 'en'
            },
            editor        : ClassicEditor,
            questionTypes : [
                { label : 'Using Bank e Auctions India.com', value : 'Using Bank e-Auctions' },
                { label : 'Account Information', value : 'Account Information' },
                { label : 'Legal Information', value : 'Legal Information' },
                { label : 'Terms & Policies', value : 'Terms & Policies' }
            ]
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Edited FAQ..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
