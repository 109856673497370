<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>FAQ</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.faqAddModal.show()" text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>

        <custom-vue-table :fields="fields" :url="listUrl" :per-page="10" class="d-sm-none-lg-block"
                          :show-search-box="false" ref="table" :extra-params="{ext_search}">

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table>
        <faq-listing-mobile-view ref="faqMobileView" :ext_search="ext_search" @viewDetails="viewDetails"
                                 @setDelete="setDelete" class="d-lg-none"></faq-listing-mobile-view>

        <modal title="Add FAQ" ref="faqAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddFAQ @success="formSuccess"></AddFAQ>
        </modal>

        <modal title="Edit FAQ" ref="faqEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditFAQ :initial-data="editingItem" @success="formSuccess"></EditFAQ>
        </modal>

        <delete-modal ref="faqDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the FAQ <b v-html="deletingItem && deletingItem.question"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.question"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddFAQ from './AddFAQ';
import EditFAQ from './EditFAQ';
import FaqListingMobileView from '../../../views/cms/faq/FaqListingMobileView';

export default {
    name : 'FAQ',

    components : { FaqListingMobileView, AddFAQ, EditFAQ },

    data () {
        return {
            ext_search : '',
            listUrl    : urls.cms.faq.list,
            deleteUrl  : urls.cms.faq.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'order',
                    sortField : 'order',
                    title     : 'Order'
                },
                {
                    name      : 'question',
                    sortField : 'question',
                    title     : 'Question'
                },
                {
                    name      : 'question_type',
                    sortField : 'question_type',
                    title     : 'Question Type'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name      : '__slot:is_enabled',
                    sortField : 'is_enabled',
                    title     : 'Enabled',
                    dataClass : 'center aligned'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewDetails (id) {
            this.$router.push('/cms/faq/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.faqAddModal.close();
            refs.faqEditModal.close();
            refs.table.refreshTable();
            refs.faqMobileView.loadData();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.faqEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.faqDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted FAQ..!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.faqDeleteModal.close();
            this.$refs.table.refreshTable();
            this.$refs.faqMobileView.loadData();
        }

    }
};
</script>

<style scoped>

</style>
